.loading-div {
  background-color: #f8f9fa; /* Set your initial light background color */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: background-color 0.5s ease; /* Add transition for smooth color change */
}

.loading-div.loading-dark {
  background-color: #343a40; /* Set your target dark background color */
}
