.loading_icon_create {
  animation: loading_icon_create 2s linear infinite;
}
@keyframes loading_icon_create {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(180deg);
  }
  /* 80% {
    transform: rotate(270deg);
  } */
  100% {
    transform: rotate(360deg);
  }
}
